import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import { Form, Button, Alert } from 'react-bootstrap';

const ContactUs = ({ onClose }) => {
  const [formData, setFormData] = useState({
    email: '',
    message: '',
  });
  const [successMessage, setSuccessMessage] = useState('');

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs
      .send(
        'service_ql7fwws',
        'template_yjmcver',
        formData,
        'FtJmvgMrCgY2ympY8'
      )
      .then(
        (response) => {
          console.log('SUCCESS!', response.status, response.text);
          setSuccessMessage('Message sent successfully!');

          // Close the modal after 2 seconds
          setTimeout(() => {
            setSuccessMessage(''); // Clear the message
            onClose(); // Call the parent component's function to close the modal
          }, 2000);
        },
        (err) => {
          console.error('FAILED...', err);
          setSuccessMessage('Failed to send message, please try again.');
          
          // Hide the message after 2 seconds
          setTimeout(() => {
            setSuccessMessage('');
          }, 2000);
        }
      );

    // Reset the form after submission
    setFormData({
      email: '',
      message: '',
    });
  };

  return (
    <div>
      {/* Display success/failure message */}
      {successMessage && (
        <Alert variant={successMessage.includes('success') ? 'success' : 'danger'} fade="true" dismissible>
          {successMessage}
        </Alert>
      )}

      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="formEmail">
          <Form.Label>Email</Form.Label>
          <Form.Control
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </Form.Group>
        <Form.Group controlId="formMessage" className="mt-2">
          <Form.Label>Message</Form.Label>
          <Form.Control
            as="textarea"
            rows={8}
            name="message"
            value={formData.message}
            onChange={handleChange}
            required
          />
        </Form.Group>
        
        <div className="d-flex justify-content-between mt-3">
        <Button
          type="submit"
          style={{ backgroundColor: "#6EA8C9", borderColor: "#6EA8C9", color: "#fff" }}
          className="px-5 custom-button submit-button"
        >
          Send
        </Button>
        <style jsx>{`
          .submit-button:hover {
            background-color: #4F91B5 !important;
            border-color: #4F91B5 !important;
          }
        `}</style>
          <Button variant="secondary" className="px-5 custom-button" onClick={onClose}>
            Close
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default ContactUs;
