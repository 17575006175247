import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom'; // Add useLocation import
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles.css';
import logo from './hacker-twins-logo.png';
import { Navbar, Nav, Modal, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXTwitter, faInstagram, faGithub, faYoutube } from '@fortawesome/free-brands-svg-icons';
import ContactUs from './ContactUs'; // Importing the ContactUs component

const NavBar = () => {
  const [showContactModal, setShowContactModal] = useState(false);
  const location = useLocation(); // Hook to get the current route

  const [isExpanded, setIsExpanded] = useState(false);
  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };
  const handleShow = () => setShowContactModal(true);
  const handleClose = () => setShowContactModal(false);

  // Determine if the current route is /robots
  const isRobotsRoute = location.pathname === '/robots';

  return (
<>
  <div style={{ id: 'hacker-twins-header', marginTop: '10px' }}>
    <Link to="/" className="navbar-link">
      <div className="d-flex justify-content-center align-items-center">
        <img src={logo} alt="Logo" className="logo" />
        <h1 className="title">Hacker Twins</h1>
      </div>
    </Link>

    <div id="tagline" className={isExpanded ? 'no-flex' : ''}>
    <div id="tagline-div"/> 
      <div className="social-links d-flex justify-content-center">
        <a href="https://x.com/hackertwinz" target="_blank" rel="noopener noreferrer" className="social-link">
          <FontAwesomeIcon icon={faXTwitter} size="2x" />
        </a>
        <a href="https://www.instagram.com/hacker_twinz" target="_blank" rel="noopener noreferrer" className="social-link">
          <FontAwesomeIcon icon={faInstagram} size="2x" />
        </a>
        <a href="https://github.com/doeswork" target="_blank" rel="noopener noreferrer" className="social-link">
          <FontAwesomeIcon icon={faGithub} size="2x" />
        </a>
        <a href="https://www.youtube.com/@hackertwins" target="_blank" rel="noopener noreferrer" className="social-link">
          <FontAwesomeIcon icon={faYoutube} size="2x" />
        </a>
      </div>
  
      <Navbar
        expand="sm"
        expanded={isExpanded}
      >
        <Navbar.Toggle onClick={handleToggle} aria-controls="basic-navbar-nav" /> {/* This adds the hamburger icon */}
        <Navbar.Collapse id="basic-navbar-nav"> {/* Wraps the navigation links */}


          <Nav className="m-auto text-center main-nav"
               style={{
                 right: '-10px',
                 bottom: '-10px',
                 border: '4px solid black',
                 borderRadius: '10px',
                 boxShadow: '4px 4px 0px #B9D9EB',
                 minWidth: '75%',
                 justifyContent: 'center', 
               }}
           >
            <Nav.Link onClick={handleShow} className="px-5 custom-nav-link">
              Contact
            </Nav.Link>
            
            <Nav.Link 
              as={Link} 
              to={"/robots"} 
              className={`px-5 custom-nav-link ${isRobotsRoute ? 'selected-link' : ''}`}>
              Robots
            </Nav.Link>
            
            <Nav.Link href="https://buy.stripe.com/test_6oE03C4XueKR0pi9AA" target="_blank" rel="noopener noreferrer" className="px-5 custom-nav-link">
              Store
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  </div>

  <Modal 
    show={showContactModal} 
    onHide={handleClose} 
    id="contact-modal"
  >
    <Modal.Header closeButton>
      <Modal.Title>Send us a message</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <ContactUs onClose={handleClose} />
    </Modal.Body>
  </Modal>
</>
  );
};

export default NavBar;
