import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Carousel } from 'react-bootstrap'; // Import Bootstrap Carousel

const PlaylistVideos = () => {
  const [tripedalVideos, setTripedalVideos] = useState([]);
  const [deskBuddyVideos, setDeskBuddyVideos] = useState([]);
  const API_KEY = process.env.REACT_APP_YOUTUBE_API_KEY;

  const tripedalPlaylistId = 'PLEucaNZ-kUSqq35XGQK2hGDhPuBRdK4N1';
  const deskBuddyPlaylistId = 'PLEucaNZ-kUSoCu26UAixnopTIWHhtfvJg';

  useEffect(() => {
    const fetchPlaylistVideos = async (playlistId, setVideos) => {
      try {
        const response = await axios.get(
          `https://www.googleapis.com/youtube/v3/playlistItems?key=${API_KEY}&playlistId=${playlistId}&part=snippet&maxResults=20`
        );
        setVideos(response.data.items);
      } catch (error) {
        console.error('Error fetching playlist videos', error);
      }
    };

    fetchPlaylistVideos(tripedalPlaylistId, setTripedalVideos);
    fetchPlaylistVideos(deskBuddyPlaylistId, setDeskBuddyVideos);
  }, [API_KEY]);

  return (
    <div>
      <br />
      <h1>Our Robots and Major Projects</h1>
      <div className="playlists-container">
        {/* Tripedal Playlist Carousel */}
        <div className="playlist-section">
          <h2>Tripedal</h2>
          <Carousel>
            {tripedalVideos.map((video) => (
              <Carousel.Item key={video.id}>
                <a
                  href={`https://www.youtube.com/watch?v=${video.snippet.resourceId.videoId}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={video.snippet.thumbnails.medium.url}
                    alt={video.snippet.title}
                    className="d-block"  // Keep only the block display
                  />
                  <Carousel.Caption>
                    <p>{video.snippet.title}</p>
                  </Carousel.Caption>
                </a>
              </Carousel.Item>
            ))}
          </Carousel>
        </div>

        {/* Desk Buddy Playlist Carousel */}
        <div className="playlist-section">
          <h2>Desk Buddy</h2>
          <Carousel>
            {deskBuddyVideos.map((video) => (
              <Carousel.Item key={video.id}>
                <a
                  href={`https://www.youtube.com/watch?v=${video.snippet.resourceId.videoId}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={video.snippet.thumbnails.medium.url}
                    alt={video.snippet.title}
                    className="d-block"  // Keep only the block display
                  />
                  <Carousel.Caption>
                    <p>{video.snippet.title}</p>
                  </Carousel.Caption>
                </a>
              </Carousel.Item>
            ))}
          </Carousel>
        </div>
      </div>
    </div>
  );
};

export default PlaylistVideos;
