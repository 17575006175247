import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import NavBar from './pages/NavBar';
import BuyNow from './pages/BuyNow';
import YouTubeGrid from './pages/YouTubeGrid';
import PlaylistVideos from './pages/PlaylistVideos';
import 'bootstrap/dist/css/bootstrap.min.css';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Router>
    <NavBar />
    <Routes>
      <Route path="/" element={<BuyNow />} />  {/* Default Route */}
      <Route path="/robots" element={<PlaylistVideos />} /> {/* Robots Route */}
    </Routes>
    <YouTubeGrid />
  </Router>

);
