import React, { useEffect } from 'react';
import './BuyNow.css'; // Add this import

const BuyNow = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://js.stripe.com/v3/buy-button.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

return (
  <div className="buy-now-container">
    <stripe-buy-button
      buy-button-id="buy_btn_1PuHGL09F6ffgeYYKqlbvL6A"
      publishable-key={process.env.REACT_APP_STRIPE_PUBLIC_KEY}
    >
    </stripe-buy-button>
  </div>
);

};

export default BuyNow;
