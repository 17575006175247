

import React, { useState, useEffect } from 'react';
import axios from 'axios';

const YouTubeGrid = () => {
  const [videos, setVideos] = useState([]);
  const API_KEY = 'AIzaSyDM7cqgchAgwn4clSzw8mz2t-qY-NF4Ljk'; // replace with your API key
  const CHANNEL_ID = 'UCPlTaPGKaY8cqX7TTh7upNQ'; // replace with the channel ID you want to fetch

  useEffect(() => {
    const fetchRecentVideos = async () => {
      try {
        // Fetch the channel's details to get the uploads playlist ID
        const channelResponse = await axios.get(
          `https://www.googleapis.com/youtube/v3/channels?part=contentDetails&id=${CHANNEL_ID}&key=${API_KEY}`
        );

        const uploadsPlaylistId =
          channelResponse.data.items[0].contentDetails.relatedPlaylists.uploads;

        let filteredVideos = [];
        let nextPageToken = '';
        
        // Continue fetching until we get 6 valid videos
        while (filteredVideos.length < 6) {
          // Fetch the next set of videos from the uploads playlist
          const playlistResponse = await axios.get(
            `https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&playlistId=${uploadsPlaylistId}&maxResults=10&pageToken=${nextPageToken}&key=${API_KEY}`
          );

          const videoIds = playlistResponse.data.items.map(video => video.snippet.resourceId.videoId).join(',');

          // Fetch details for each video to get the duration
          const videoDetailsResponse = await axios.get(
            `https://www.googleapis.com/youtube/v3/videos?part=contentDetails&id=${videoIds}&key=${API_KEY}`
          );

          const validVideos = playlistResponse.data.items.filter((video, index) => {
            const duration = videoDetailsResponse.data.items[index].contentDetails.duration;
            const seconds = convertDurationToSeconds(duration);
            return seconds > 60; // Only include videos longer than 60 seconds
          });

          filteredVideos = [...filteredVideos, ...validVideos];
          nextPageToken = playlistResponse.data.nextPageToken;

          if (!nextPageToken) break; // Stop if there are no more videos to fetch
        }

        setVideos(filteredVideos.slice(0, 6)); // Take only the first 6 valid videos
      } catch (error) {
        console.error('Error fetching videos:', error);
      }
    };

    fetchRecentVideos();
  }, []);

  // Helper function to convert ISO 8601 duration to seconds
  const convertDurationToSeconds = (duration) => {
    const regex = /PT(\d+H)?(\d+M)?(\d+S)?/;
    const matches = duration.match(regex);
    const hours = (parseInt(matches[1]) || 0);
    const minutes = (parseInt(matches[2]) || 0);
    const seconds = (parseInt(matches[3]) || 0);
    return hours * 3600 + minutes * 60 + seconds;
  };

  return (
    <div>
      <h2>Most Recent Videos </h2>
      <div style={styles.grid}>
        {videos.map((video) => (
          <div key={video.snippet.resourceId.videoId} style={styles.card}>
            <a
              href={`https://www.youtube.com/watch?v=${video.snippet.resourceId.videoId}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={video.snippet.thumbnails.medium.url}
                alt={video.snippet.title}
                style={styles.thumbnail}
              />
              <h3>{video.snippet.title}</h3>
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

const styles = {
  grid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
    gap: '16px',
  },
  card: {
    border: '1px solid #ccc',
    borderRadius: '8px',
    padding: '16px',
    textAlign: 'center',
  },
  thumbnail: {
    width: '100%',
    height: 'auto',
    borderRadius: '4px',
  },
};

export default YouTubeGrid;
